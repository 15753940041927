

























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { AppModule, Hotel, Customer, hotelConverter, customerConverter } from '@/store/app'
import { ValidationObserver } from 'vee-validate';
import firebase from 'firebase/app';
import 'firebase/firestore';
import fs = firebase.firestore;
const db = firebase.firestore();
const functions = firebase.app().functions('asia-northeast2');
const sendMail = functions.httpsCallable('sendMail');

import BaseInput from '@/components/BaseInput.vue'
import BaseInputFile from '@/components/BaseInputFile.vue'
import BaseButton from '@/components/BaseButton.vue'
import AddButton from '@/components/AddButton.vue'
import Panel from '@/components/Panel.vue'

import { zipToAddress } from '@/zipToAddress'
@Component({
  components: {
    BaseInput,
    BaseButton,
    BaseInputFile,
    AddButton,
    ValidationObserver,
    Panel
  },
})
export default class HotelView extends Vue {
  private logo = '';
  private image = '';
  private previewImage = '';
  private customer = {} as Customer;
  private acceptTac = false;
  private companions = [] as string[];
  private arrivalDateStr = this.todayString();
  private idImage: File = {} as File;

  $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  };

  private get hotel() {
    return AppModule.hotel;
  }
  mounted() {
    this.customer.companions = [];
    db.collection("hotels").doc(this.$route.params.id).withConverter(hotelConverter).get().then((hotel)=>{
      AppModule.setHotel(hotel.data()!);
      firebase.storage().ref().child(`/hotels/${hotel.id}/${hotel.id}`).getDownloadURL().then((v)=>{
        this.image = v;
      }).catch((e)=>{
        this.image = require('@/assets/no-image.png');
      });
      firebase.storage().ref().child(`/hotels/${hotel.id}/logo`).getDownloadURL().then((v)=>{
        this.logo = v;
      }).catch((e)=>{
        this.logo = require('@/assets/no-image.png');
      });
    });
  }

  private async add(){
    if(!(await this.$refs.observer.validate()))return;
    if(!this.acceptTac)return;
    db.collection("hotels").doc(this.$route.params.id).collection("customers").withConverter(customerConverter).add({...this.customer, isCheckedIn: false, companions: this.companions, createdAt: new Date(), arrivalDate: new Date(this.arrivalDateStr)}).then((res)=>{
      this.uploadImage(res.id);
      // functions.useFunctionsEmulator('http://localhost:5001');
      sendMail({...this.customer, id: res.id, hotel: this.hotel, arrivalDate: this.arrivalDateStr}).then(res=>{
        console.log("成功");
      }).catch(e=>{
        console.log("失敗");
      });
      firebase.analytics().logEvent('regist_checkin');
      this.$router.push(`/checkin/${this.$route.params.id}/${res.id}`);
    });
  }

  private addCompanion(){
    this.companions.push('');
  }

  private removeCompanion(i: number){
    this.companions.splice(i, 1);
  }

  private onChangeImage(e: any) {
    this.idImage = e.target.files[0];
    this.previewImage = URL.createObjectURL(this.idImage);
  }

  private uploadImage(id: string){
    if(!this.idImage.size)return;
    console.log('up')
    const storageRef = firebase.storage().ref();
    const task = storageRef.child(`/ids/${this.hotel.id}/${id}`).put(this.idImage);
  }

  private todayString(){
    const today = new Date();
    today.setDate(today.getDate());
    const yyyy = today.getFullYear();
    const mm = ("0"+(today.getMonth()+1)).slice(-2);
    const dd = ("0"+today.getDate()).slice(-2);
    return `${yyyy}-${mm}-${dd}`;
  }

  private async getAddress(){
    const address = await zipToAddress(this.customer.zip);
    Vue.set(this.customer, 'address', address);
  }
}
