import { render, staticRenderFns } from "./HotelView.vue?vue&type=template&id=fd216414&scoped=true&"
import script from "./HotelView.vue?vue&type=script&lang=ts&"
export * from "./HotelView.vue?vue&type=script&lang=ts&"
import style0 from "./HotelView.vue?vue&type=style&index=0&id=fd216414&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd216414",
  null
  
)

export default component.exports